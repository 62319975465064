<template>
  <div>
    <img
      src="../assets/images/BrokerLicense.jpg"
      alt=""
      style="width: 100%; height: auto"
    />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>